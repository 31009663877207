<template>
  <div class="body">
    <div class="caveat">
      <div style="text-align: center"><span style="color: red;margin-right: 20px;">禁止访问 !  </span>请使用chrome/360/搜狗浏览器</div>

      <div style="text-align: center">
        <img src="@/assets/images/caveat.jpeg" alt="">
      </div>
    </div>

  </div>
</template>

<script>
export default {
name: "caveat"
}
</script>

<style lang="less" scoped>
.body {
  height: 100%;
  background-color: #F6F6F6;
}
.caveat {
  width: 50%;
  margin: 0 auto;
  padding-top: 50px;
  font-size: 20px;
  color: #dd820c;
}
</style>
